import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="number"]',
})
export class DisableInputScrollDirective {
  @Input() enableScroll = false;

  @HostListener('wheel', ['$event'])
  @HostListener('scroll', ['$event'])
  handler(event: Event) {
    if (!this.enableScroll) {
      event.preventDefault();
    }
  }
}
